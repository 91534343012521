.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    
  }
}
html, body, #root, #root>div {
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.contentLegal a {
  background-color: #fff;
  background-color: #fff;
  color: #000!important;
  padding:4px!important;
  border-radius: 4px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contentLegal {
  color: #fff;
  max-width: 1000px;
}
.contentLegal>div, .contentLegal>p, .contentLegal>span, .contentLegal>h1, .contentLegal>h2, .contentLegal>h3, .contentLegal>ul, .contentLegal>ol, .contentLegal>li, .contentLegal>h4 {
  color: #fff;
}


#Share Content
:selection{
  color: #fff;
  background: #7d2ae8;
}
.shareContent .view-modal, .shareContent .popup{
  position: absolute;
  left: 50%;
}
.shareContent button{
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: background 0.1s linear, border-color 0.1s linear, color 0.1s linear;
}
.shareContent .view-modal{
  top: 10%;
  left: 90%;
  color: #e8e4ee;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 25px;
  background: rgb(113, 5, 156);
  transform: translate(-50%, -50%);
}
.shareContent .popup{
  background: rgb(255, 254, 254);
  padding: 25px;
  border-radius: 15px;
  top: 10%;
  max-width: 380px;
  width: 100%;
  pointer-events: none;
  box-shadow: 0px 10px 15px rgba(0,0,0,0.1);
  transform: translate(-50%, -50%) scale(1.2);
  transition: top 0s 0.2s ease-in-out,
              opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
}
.shareContent .popup.show{
  top: 50%;
  left: 50%;
  opacity: 1;
  pointer-events: auto;
  transform:translate(-50%, -50%) scale(1);
  transition: top 0s 0s ease-in-out,
              opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;

}
.shareContent .popup :is(header, .icons, .field){
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shareContent .popup header{
  padding-bottom: 15px;
  border-bottom: 1px solid #ebedf9;
}
.shareContent header span{
  font-size: 21px;
  font-weight: 600;
}
h.shareContent eader .close, .shareContent .icons a{
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  transition: all 0.3s ease-in-out;
} 
.shareContent header .close{
  color: #878787;
  font-size: 17px;
  background: #f3f3f3;
  height: 33px;
  width: 33px;
  cursor: pointer;
}
.shareContent header .close:hover{
  background: #ebedf9;
}
.shareContent .popup .content{
  margin: 20px 0;
}
.shareContent .popup .icons{
  margin: 15px 0 20px 0;
}
.shareContent .content p{
  font-size: 16px;
}
.shareContent .content .icons a{
  height: 50px;
  width: 50px;
  font-size: 20px;
  text-decoration: none;
  border: 1px solid transparent;
}
.shareContent .icons a i{
  transition: transform 0.3s ease-in-out;
}
.shareContent .icons a:nth-child(1){
  color: #1877F2;
  border-color: #b7d4fb;
}
.shareContent .icons a:nth-child(1):hover{
  background: #1877F2;
}
.shareContent .icons a:nth-child(2){
  color: #46C1F6;
  border-color: #b6e7fc;
}
.shareContent .icons a:nth-child(2):hover{
  background: #46C1F6;
}
.shareContent .icons a:nth-child(3){
  color: #e1306c;
  border-color: #f5bccf;
}
.shareContent .icons a:nth-child(3):hover{
  background: #e1306c;
}
.shareContent .icons a:nth-child(4){
  color: #25D366;
  border-color: #bef4d2;
}
.shareContent .icons a:nth-child(4):hover{
  background: #25D366;
}
.shareContent .icons a:nth-child(5){
  color: #0088cc;
  border-color: #b3e6ff;
}
.shareContent .icons a:nth-child(5):hover{
  background: #0088cc;
}
.shareContent .icons a:hover{
  color: #fff;
  border-color: transparent;
}
.shareContent .icons a:hover i{
  transform: scale(1.2);
}
.shareContent .content .field{
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #757171;
}
.shareContent .field.active{
  border-color: #7d2ae8;
}
.shareContent .field i{
  width: 50px;
  font-size: 18px;
  text-align: center;
}
.shareContent .field.active i{
  color: #7d2ae8;
}
.shareContent .field input{
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}
.shareContent .field button{
  color: #fff;
  padding: 5px 18px;
  background: #7d2ae8;
}
.shareContent .field button:hover{
  background: #8d39fa;
}